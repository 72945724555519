<template>
  <div class="w-full">
    <div class="flex justify-center items-center">
      <p class="text-6xl mt-12">🎉 🥳 🎉</p>
    </div>
    <h2 class="font-bold text-2xl"></h2>
    <p class="text-gray-700">
      Controlla il tuo indirizzo email, ti abbiamo inviato una email per
      confermare i tuoi dati, una volta confermato i tuoi dati potrai accedere
      al tuo negozio e iniziare a caricare e a vendere i tuoi prodotti.
    </p>
    <p class="mt-4 text-gray-700">
      Abbiamo creato per te anche un account Remoney, il quale ti permetterà di
      ricevere i tuoi pagamenti.
    </p>
    <div class="flex items-baseline my-4 w-full">
      <h2 class="font-bold text-2xl flex-grow">Scarica l'app</h2>
      <div class="font-bold">Re•money</div>
    </div>
    <div class="w-full flex justify-center items-center">
      <router-link :to="`/${$route.params.lang}/login`" class="btn btn-cta"
        >Accedi</router-link
      >
    </div>
    <div class="w-full">
      <router-link to="/" class="w-full text-center text-secondary-500">
        <i class="mdi mdi-arrow-left"></i>
        Torna alla Home</router-link
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("createstore", ["phonenumber"])
  },
  data() {
    return {
      otp: ""
    };
  }
};
</script>

<style>
.shape {
  width: 500px;
  height: 400px;

  animation-name: appear;
  animation-duration: 10s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.shape .fil0 {
  fill: url(#header-shape-gradient) #fff;
}

#header-shape-gradient {
  --color-stop: theme("colors.secondary.300"); /*#f12c06;*/
  --color-bot: theme("colors.secondary.500"); /*#faed34;*/
}

@keyframes appear {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  /* 50% {
    
    opacity: 0.75;
  } */
  10% {
    transform: translateX(0%);
    opacity: 1;
  }
  95% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(50%);
    opacity: 0;
  }
}
</style>
